import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/pages/main/main.vue'  
import Privacy from '@/pages/apps/diary/privacy.vue'
import DeleteId from '@/pages/apps/diary/deleteid.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',  // URL에 해시(#)가 없는 모드를 사용합니다.
  routes: [
    {
      path: '/',  // 브라우저에서 접속할 경로를 지정합니다.
      name: 'Main',
      component: Main  // 해당 경로에 매핑되는 컴포넌트를 지정합니다.
    },
    {
      path: '/diary-privacypolicy',  // 브라우저에서 접속할 경로를 지정합니다.
      name: 'Privacy',
      component: Privacy  // 해당 경로에 매핑되는 컴포넌트를 지정합니다.
    },
    {
      path: '/diary-deletepolicy',  // 브라우저에서 접속할 경로를 지정합니다.
      name: 'DeleteId',
      component: DeleteId  // 해당 경로에 매핑되는 컴포넌트를 지정합니다.
    },
  ]
})